import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"scroll-fab":""}},[_c(VCard,{style:(("overflow-y: auto; " + (_vm.getViewHeightStyle(36)))),attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"pb-8 px-5"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('section-header',{attrs:{"hide-divider":"","title":"Your Cart"}}),_c('order-items',{attrs:{"editable":"","currency":_vm.getCartCurrency,"items-feedback":_vm.feedback},model:{value:(_vm.getCartItems),callback:function ($$v) {_vm.getCartItems=$$v},expression:"getCartItems"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('section-header',{attrs:{"hide-divider":"","reverse":"","title":_vm.$t('cart.orderSummary')}}),_c(VCard,[_c('store-card',{staticClass:"mb-1",attrs:{"store":_vm.getCartStore,"banner":"","round":""}}),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._v(" Deliver To: "),_c('b',[_vm._v(_vm._s(_vm.getDefaultAddressStringNoTitle))]),(_vm.outOfAos)?_c(VAlert,{staticClass:"body-2 lighten-1 px-1 py-0 mb-1",attrs:{"type":"error"}},[_vm._v("Address is out of store area of service")]):_vm._e()],1),_c(VSimpleTable,{staticClass:"grey lighten-4",attrs:{"dense":"","light":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_vm._l((_vm.getCartSummary),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.getCartCurrency + " " + item.price)+" ")])])}),_c('tr',[_c('td',{class:_vm.isFreeDelivery ? 'text-decoration-line-through' : ''},[_vm._v(" "+_vm._s(_vm.$t("cart.deliveryFees"))+" ")]),_c('td',{class:_vm.isFreeDelivery
                            ? 'text-decoration-line-through text-right'
                            : 'text-right'},[_vm._v(" "+_vm._s(_vm.getCartCurrency + " " + (_vm.getCartStore ? _vm.getCartStore.deliveryFees : 0))+" ")])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("cart.grandTotal"))+" ")]),_c('td',{staticClass:"font-weight-bold text-right"},[_vm._v(" "+_vm._s(_vm.getCartCurrency + " " + (_vm.notEmptyArray(_vm.getCartItems) ? _vm.getCartGrandTotal : 0))+" ")])])],2)]},proxy:true}])}),_c(VCardText,[(!_vm.isMinimum)?_c(VAlert,{staticClass:"body-2 lighten-1 px-1 py-0 mb-1",attrs:{"type":"error","dense":""}},[_vm._v(" The minimum order price for this store is ("+_vm._s(_vm.getMinOrderPrice)+"), please add a few more items. ")]):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":"","color":"secondary primary--text"},on:{"click":_vm.goToStore}},[_c(VIcon,[_vm._v(" mdi-store ")]),_vm._v(" Add Items ")],1),_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","color":"primary secondary--text","disabled":!_vm.notEmptyArray(_vm.getCartItems) || _vm.isCartLocked || _vm.outOfAos || !_vm.isMinimum},on:{"click":_vm.proceedToCheckout}},[_c(VIcon,[_vm._v(" mdi-check-bold ")]),_vm._v(" Proceed to Checkout ")],1)],1)],1)],1)],1)],1),_c('br')],1)]),_c('locked-cart',{attrs:{"store-items":"","address":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }